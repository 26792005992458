import { defineStore } from "pinia";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";

export const useGratisProductsDetailStore = defineStore(
  "gratis-products",
  () => {
    if (!isInNuxtInstance())
      return {
        product: null,
        addProduct: () => {},
        pending: true,
        remainProductsQuantity: {},
      };

    const { addProduct } = useCart();
    const {
      pending: gratisProductsPending,
      data: gratisProducts,
      execute: refetchGratisProducts,
    } = useAsyncData("gratis-products", () => {
      return shopwareExtensionsApi.methods.crehler.gratisProducts.getGratisProducts();
    });

    const firstAvailableProduct = computed(() => {
      const promotionWithAvailableQuantity = gratisProducts.value?.find(
        (product) => product.quantity > 0,
      );
      return promotionWithAvailableQuantity?.availableProducts?.[0];
    });

    const handleAddProduct = () => {
      if (!firstAvailableProduct.value?.id) return;

      addProduct({
        id: firstAvailableProduct.value.id,
        quantity: 1,
      });
    };

    const remainProductsQuantity = computed(() => {
      return gratisProducts.value?.reduce((acc, promotion) => {
        const promotionProductsQuantityLeft =
          promotion.availableProducts.reduce((promotionAcc, product) => {
            promotionAcc[product.id] = promotion.quantity;

            return promotionAcc;
          }, {});

        return { ...acc, ...promotionProductsQuantityLeft };
      }, {});
    });

    return {
      product: firstAvailableProduct,
      addProduct: handleAddProduct,
      refetchGratisProducts,
      pending: gratisProductsPending,
      remainProductsQuantity,
    };
  },
);
